@tailwind base;
@tailwind components;
@tailwind utilities;



html, body {
  margin: 0;
  padding: 0;
  min-height: 100%;
}
html {
  scroll-behavior: smooth;
}
.App {
  min-height: 100vh;
}

